<template>
  <div class="content">
    <!-- 标题/更多 -->
    <div class="more-top">
        <div class="title">{{item.title}}</div>
        <div @click="moreMethod(item.title)" class="more">更多</div>
        <div class="more-img">
            <img src="../../assets/home/right_arrow1.png" alt="">
        </div>
    </div>
    <!-- 列表 -->
    <div class="list">
        <div @click="detailsMethod()" v-for="(item1,index) in item.list" :key="index">
			<!-- 内容 -->
			<div v-if="index==0" class="content-top" @click.stop="detailsMethod(item1.noticeId)">
			    <div class="content-img">
			        <img :src="item1.img" alt="">
			    </div>
			    <div class="content-title">{{item1.synopsis}}</div>
			</div>
			<div v-if="index!=0" class="list-title" @click.stop="detailsMethod(item1.noticeId)">{{item1.noticeTitle}}</div>
		</div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    methods:{
        /**更多按钮**/
        moreMethod(title){
            this.$router.push('/news');
            this.$store.commit('changClick',1)
            if (title=='新闻') {
                this.$store.commit('changNew',1)
                sessionStorage.setItem('newLeft',1)
            }else{
                this.$store.commit('changNew',2)
                sessionStorage.setItem('newLeft',2)
            }
        },
        detailsMethod(noticeId){
            this.$router.push({
				path:'/newDetails',
				query:{
					newID:noticeId,
				}
			});
            this.$store.commit('changClick',1)
        }
    }
}
</script>

<style scoped lang="scss">
.content{
    width: 275px;
    padding-left: 10px;
    background-color: #FCFCFC 0px;
    border-radius: 0px 0px 4px 4px;
    .more-top{
        display: flex;
        justify-items: center;
        align-items: center;
        padding-top: 15px;
        position: relative;
        .title{
            font-weight: 500;
            font-size: 18px;
            line-height: 25px;
            color: #333333;
        }
        .more-img{
            position: absolute;
            right: 10px;
        }
        .more{
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            color: rgba(51, 51, 51, 0.8);
            position: absolute;
            right: 20px;
            cursor: pointer;
        }
    }
    .list{
        margin-top: 10px;
        padding-bottom: 10px;
		.content-top{
		    display: flex;
		    justify-items: center;
		    align-items: center;
		    margin-top: 20px;
		    position: relative;
		    cursor: pointer;
		    .content-img{
		        width: 81px;
		        height: 81px;
				> img{
					width: 100%;
					height: 100%;
				}
		    }
		    .content-title{
		        width: 190px;
		        margin-left: 10px;
		        overflow: hidden;
		        font-size: 12px;
		        line-height: 17px;
		        color: #333333;
		        position: absolute;
		        top: 0px;
		        right: 0px;
		    }
		}
        .list-title{
            border-top: 1px solid #DDDDDD;
            font-size: 12px;
            line-height: 37px;  
            color: #333333;
            height: 37px;
            overflow: hidden;
            cursor: pointer;
        }
    }
}
</style>