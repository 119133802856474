<template>
  <div class="main-content">
    <div class="banner">
      <el-carousel height="618px">
        <el-carousel-item v-for="(item,index) in bannerList" :key="index">
          <img class="banner-img" :src="item.imgUrl" alt="" @click.stop="TZMethod(item.linkUrl,item.content)">
        </el-carousel-item>
      </el-carousel>
      <navigationBar class="avigation"></navigationBar>
    </div>
    <div class="content-view">
      <!-- 新书推荐 -->
      <div class="new-book">
        <div class="newbook-list">
          <div class="newbook-top" :style="{backgroundColor:isSkin}">
            <div class="thumb">
              <img src="../../assets/home/thumb1.png" alt="">
            </div>
            <div class="thumb-title">新书推荐</div>
            <div @click="newMoreMethod()" class="more-title">更多</div>
            <div class="more">
              <img src="../../assets/home/right_arrow.png" alt="">
            </div>
          </div>
          <div class="new-list">
            <newBook v-for="(item,index) in booksList" :key="index" :index="index" :item="item"></newBook>
          </div>
        </div>
        <div class="newbook-right">
          <div class="more-top">
            <moreList :item="moreItem"></moreList>
          </div>
          <div class="more-but">
            <moreList :item="moreItem1"></moreList>
          </div>
          <div class="bookDownload" @click="bookDownloadMethod()">
            <img src="../../assets/home/bookDownload.png" alt="">
          </div>
        </div>
      </div>
      <!-- 精品图书 -->
      <div class="quality-books">
        <div class="quality-top" :style="{backgroundColor:isSkin}">
          <div class="thumb">
            <img src="../../assets/home/quality.png" alt="">
          </div>
          <div class="thumb-title">精品图书</div>
          <div @click="qualityMoreMethod()" class="more-title">更多</div>
          <div class="more">
            <img src="../../assets/home/right_arrow.png" alt="">
          </div>
        </div>
        <div class="quality-view">
          <div class="left-menu" :style="{backgroundColor:isSkin}">
            <leftMenu v-for="(item,index) in qualityItem" :key="index" :index="cur" :cur="index" :item="item" @cilckMethod="cilckMethod"></leftMenu>
          </div>
          <div class="quality-list">
            <qualityBook v-for="(item,index) in bookQList" :key="index" :index="index" :item="item"></qualityBook>
          </div>
        </div>
      </div>
      <!-- 猜你喜欢 -->
      <div class="Guess-books">
        <div class="Guess-left">
          <div class="Guess-top" :style="{backgroundColor:isSkin}">
            <div class="thumb">
              <img src="../../assets/home/guess.png" alt="">
            </div>
            <div class="thumb-title">猜你喜欢</div>
          </div>
          <div class="left-view">
            <guessBook v-for="(item,index) in likeItem" :key="index" :index="index" :item="item"></guessBook>
          </div>
        </div>
        <div class="Guess-right" :style="{backgroundColor:isSkin}">
          <div class="right-top" :style="{backgroundColor:isSkin}">榜单排行</div>
          <div class="right-content">
            <div class="right-left">
              <div class="Ranking">热门排行</div>
              <div>
                <bookRank v-for="(item,index) in hotList" :key="index" :index="index" :item="item" :leng="rankList.length"></bookRank>
              </div>
            </div>
            <div class="right-right">
              <div class="Ranking">专著排行</div>
              <div>
                <bookRank v-for="(item,index) in rankList" :key="index" :index="index" :item="item" :leng="rankList.length"></bookRank>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 查看 -->
      <div class="but-img" @click="seeMethod()">
        <img src="../../assets/home/seeimg.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import navigationBar from '../../components/layout/app_navigationBar'
import newBook from './components/newBook-box'
import qualityBook from './components/qualityBook-box'
import guessBook from './components/guessBook-box'
import moreList from '../../components/tools/moreList-box'
import leftMenu from '../../components/tools/leftMenu-box'
import bookRank from './components/bookRank-box'
export default {
	components:{navigationBar,newBook,moreList,leftMenu,qualityBook,guessBook,bookRank},
	data() {
		return {
			cur:0,
			bannerList:[],
			booksList:[],
			bookQList:[],
			moreItem:{
				title:'新闻',
				list:[]
			},
			moreItem1:{
				title:'活动',
				list:[]
			},
			qualityItem:[],
			likeItem:[],
			hotList:[],
			rankList:[],
		}
	},
	created(){
    // this.$router.push({ path:'/books'  })
		this.$store.commit('changClick',0)
    this.getUserInfo()
		this.getSystemColor()
		this.getBanner()
		this.getNewBooks()
		this.getNotice()
		this.getBoutiqueCategory()
		this.getLikereCommendBooks()
		this.getRankingList()
	},
	computed:{
		isSkin(){
			return this.$store.state.isSkin
		},
	},
	methods:{
    /**获取用户信息**/
    getUserInfo(){
      let that = this
      that.$api.getUserInfo({
        file:'',
      }).then(res=>{
        if (res.data.code == 0){
          sessionStorage.setItem('userNickName',res.data.data.nickname)
          sessionStorage.setItem('userHeadImg',res.data.data.headImgUrl)
          sessionStorage.setItem('bindWechat',res.data.data.bindWechat)
          this.$store.commit('changNickname',res.data.data.nickname)
          this.$store.commit('changHeadImgUrl',res.data.data.headImgUrl)
          this.$store.commit('changTeachers',res.data.data.identity)
          sessionStorage.setItem('Teachers',res.data.data.identity)
        }
      })
    },
		/**获取背景颜色**/
		getSystemColor(){
			var that = this
			that.$api.getSystemColor({}).then(res=>{
				if (res.data.code == 0) {
					sessionStorage.setItem('Skin',res.data.data.colorValue)
					sessionStorage.setItem('ZSkin',res.data.data.buttonColor)
					this.$store.commit('changSkin',res.data.data.colorValue)
					this.$store.commit('changZSkin',res.data.data.buttonColor)
				}
			})
		},
		/**banner**/
		getBanner(){
			var that = this
			that.$api.getBanner({}).then(res=>{
				if (res.data.code == 0) {
					that.bannerList = res.data.data
				}else{
					that.bannerList = []
				}
			})
		},
		/**获取新书推荐**/
		getNewBooks(){
			var that = this
			that.$api.getNewBooks({}).then(res=>{
				if (res.data.code == 0) {
					that.booksList = res.data.data
				}else{
					that.booksList = []
				}
			})
		},
		/**公告信息**/
		getNotice(){
			var that = this
			that.$api.getNotice({params:{noticeType:2,pageNum:1,pageSize:5}}).then(res=>{
				if (res.data.code == 0) {
					that.moreItem.list = res.data.data.list
				}else{
					that.moreItem.list = []
				}
			}),
			that.$api.getNotice({params:{noticeType:3,pageNum:1,pageSize:5}}).then(res=>{
				if (res.data.code == 0) {
					that.moreItem1.list = res.data.data.list
				}else{
					that.moreItem1.list = []
				}
			})
		},
		/**精品图书分类**/
		getBoutiqueCategory(){
			var that = this
			that.$api.getBoutiqueCategory({}).then(res=>{
				if (res.data.code == 0) {
					that.qualityItem = res.data.data
				}else{
					that.qualityItem = []
				}
			})
		},
		/**获取精品图书**/
		getQualityBooks(dictCode){
			var that = this
			that.$api.getQualityBooks({
				params:{
					dictCode:dictCode,
					pageNum:1,
					pageSize:10,
					sort:''
				},
			}).then(res=>{
				if (res.data.code == 0) {
					that.bookQList = res.data.data.list
				}else{
					that.bookQList = []
				}
			})
		},
		/**获取猜你喜欢图书**/
		getLikereCommendBooks(){
			var that = this
			that.$api.getLikereCommendBooks({}).then(res=>{
				if (res.data.code == 0) {
					that.likeItem = res.data.data
				}else{
					that.likeItem = []
				}
			})
		},
		/**获取排行榜**/
		getRankingList(){
			var that = this
			that.$api.getRankingList({params:{type:1}}).then(res=>{
				if (res.data.code == 0) {
					that.hotList = res.data.data
				}else{
					that.hotList = []
				}
			}),
			that.$api.getRankingList({params:{type:2}}).then(res=>{
				if (res.data.code == 0) {
					that.rankList = res.data.data
				}else{
					that.rankList = []
				}
			})
		},
    /**banner跳转**/
    TZMethod(url,item){
      var reg = RegExp(/http/);
      var regs = RegExp(/https/);
      if(reg.exec(url) || regs.exec(url)){
        window.open(url)
      }
    },
		/**新书推荐更多按钮**/
		newMoreMethod(){
			this.$router.push('/books');
			this.$store.commit('changClick',1)
		},
		/**书目下载按钮**/
		bookDownloadMethod(){
			this.$router.push('/bookDownload');
			this.$store.commit('changClick',1)
		},
		/**精品图书更多按钮**/
		qualityMoreMethod(){
		  this.$router.push('/boutiqueBook');
		  this.$store.commit('changClick',1)
		},
		/**图书菜单点击按钮**/
		cilckMethod(index,code){
		  this.cur = index;
		  this.getQualityBooks(code)
		},
		/**查看**/
		seeMethod(){
		  this.$router.push('/schools');
		  this.$store.commit('changClick',0)
		}
	}
}
</script>

<style scoped lang="scss">
.banner{
  margin-top: -80px;
  position: relative;
  .banner-img{
    cursor: pointer;
	  width: 100%;
	  height: 100%;
  }
  .avigation{
    position: absolute;
    top: 0px;
  }
}
.content-view{
  padding-top: 50px;
  width: 100%;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  // 新书推荐
  .new-book{
    width: 1200px;
    background-color: forestgreen 0px;
    display: flex;
    display: -webkit-flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .newbook-list{
      width: 895px;
      .newbook-top{
        width: 100%;
        height: 55px;
        background: #CCA2A2;
        border-radius: 4px;
        display: flex;
        justify-items: center;
        align-items: center;
        position: relative;
        .thumb{
          margin-left: 15px;
          width: 15px;
          height: 15px;
          display: flex;
          justify-items: center;
          align-items: center;
        }
        .thumb-title{
          font-size: 18px;
          line-height: 55px;
          color: #FFFFFF;
          margin-left: 10px;
          font-weight: 500;
        }
        .more{
          position: absolute;
          right: 20px;
        }
        .more-title{
          font-size: 13px;
          line-height: 18px;
          color: rgba(255, 255, 255, 0.9);
          position: absolute;
          right: 30px;
          font-weight: normal;
          cursor: pointer;
        }
      }
      .new-list{
        display: flex;
        display: -webkit-flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding-top: 20px;
      }
    }
    .newbook-right{
      margin-left: 20px;
      width: 285px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .more-top{
        background-color: #FFF 0px;
      }
      .more-but{
        margin-top: 15px;
      }
      .bookDownload{
        width: 100%;
        height: 76px;
        cursor: pointer;
        padding-bottom: 30px;
        padding-top: 10px;
      }
    }
  }
  // 精品图书
  .quality-books{
    width: 1200px;
    background-color: forestgreen 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .quality-top{
        width: 100%;
        height: 55px;
        background: #CCA2A2;
        border-radius: 4px;
        display: flex;
        justify-items: center;
        align-items: center;
        position: relative;
        .thumb{
          margin-left: 15px;
          width: 15px;
          height: 15px;
          display: flex;
          justify-items: center;
          align-items: center;
        }
        .thumb-title{
          font-size: 18px;
          line-height: 55px;
          color: #FFFFFF;
          margin-left: 10px;
          font-weight: 500;
        }
        .more{
          position: absolute;
          right: 20px;
        }
        .more-title{
          font-size: 13px;
          line-height: 18px;
          color: rgba(255, 255, 255, 0.9);
          position: absolute;
          right: 30px;
          font-weight: normal;
          cursor: pointer;
        }
      }
      .quality-view{
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-items: center;
        align-items: flex-start;
        .left-menu{
          width: 184px;
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 20px;
		      margin-bottom: 20px;
          padding-top: 10px;
        }
        .quality-list{
          width: 100%;
          margin-left: 20px;
          display: flex;
          display: -webkit-flex;
          justify-content: flex-start;
          flex-wrap: wrap;
        }
      }
  }
  // 猜你喜欢
  .Guess-books{
    width: 1200px;
    background-color: forestgreen 0px;
    display: flex;
    justify-items: flex-start;
    align-items: flex-start;
    .Guess-left{
      width: 793px;
      .Guess-top{
        width: 100%;
        height: 55px;
        background: #CCA2A2;
        border-radius: 4px;
        display: flex;
        justify-items: center;
        align-items: center;
        position: relative;
        .thumb{
          margin-left: 15px;
          width: 15px;
          height: 15px;
          display: flex;
          justify-items: center;
          align-items: center;
        }
        .thumb-title{
          font-size: 18px;
          line-height: 55px;
          color: #FFFFFF;
          margin-left: 10px;
          font-weight: 500;
        }
        .more{
          position: absolute;
          right: 20px;
        }
        .more-title{
          font-size: 13px;
          line-height: 18px;
          color: rgba(255, 255, 255, 0.9);
          position: absolute;
          right: 30px;
          font-weight: normal;
          cursor: pointer;
        }
      }
      .left-view{
        margin-top: 20px;
        display: flex;
        display: -webkit-flex;
        justify-content: flex-start;
        flex-wrap: wrap;
      }
    }
    .Guess-right{
      width: 387px;
	  margin-left: 20px;
      padding-bottom: 1px;
      border-radius: 4px;
      .right-top{
        width: 100%;
        height: 55px;
        font-size: 18px;
        line-height: 55px;
        text-align: center;
        color: #FFFFFF;
        background: #CAB79A;
        border-radius: 4px 4px 0px 0px;
      }
      .right-content{
        background-color: #FFF;
        width: calc(100% - 2px);
        margin-left: 1px;
        border-radius: 0px 0px 4px 4px;
        display: flex;
        display: -webkit-flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .right-left{
          width: 183px;
          .Ranking{
            font-size: 12px;
            line-height: 17px;
            color: #333333;
            text-align: center;
            padding: 15px;
          }
        }
        .right-right{
          width: 183px;
          margin-left: 18px;
          .Ranking{
            font-size: 12px;
            line-height: 17px;
            color: #333333;
            text-align: center;
            padding: 15px;
          }
        }
      }
    }
  }
  // 查看
  .but-img{
    width: 1200px;
    height: 350px;
    margin-top: 10px;
    margin-bottom: 75px;
    cursor: pointer;
  }
}

</style>
