<template>
  <div v-if="item != null" class="content" @click="detailsMethod()" :class="{distance1:(index+1)%5==0}">
      <div class="book-img">
          <el-image v-if="item != null" :src="item.img"></el-image>
      </div>
      <div class="title" v-if="item != null">{{'《'+item.name+'》'}}</div>
      <div class="name" v-if="item != null">{{item.opus}}</div>
      <div class="price" v-if="item != null">{{'¥'+item.price}}</div>
  </div>
</template>

<script>
export default {
    props:{
        displayType:{
            type:Number,
            default:0
        },
        index:{
            type:Number,
            default:0
        },
        item:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    methods:{
        detailsMethod(){
            this.$router.push({
                path:'/bookDetails',
                query:{
                    bookID:this.item.id,
                }
            });
            this.$store.commit('changClick',1)
        }
    }
}
</script>

<style scoped lang="scss">
.content{
    width: 184px;
    height: 280px;
    margin-bottom: 65px;
    margin-right: 19px;
    cursor: pointer;
    .book-img{
        width: 100%;
        height: 275px;
		    display: flex;
        justify-items: center;
        align-items: center;
		    border-radius: 5px;
        overflow: hidden;
		> img{
			width: 100%;
			height: 100%;
			border-radius: 5px;
		}
    }
    .title{
        width: 100%;
        height: 20px;
        overflow: hidden;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #333333;
        margin-top: 5px;
    }
    .name{
        width: 100%;
        height: 20px;
        overflow: hidden;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        color: #999999;
        margin-top: 3px;
        margin-left: 2px;
    }
    .price{
        width: 100%;
        height: 30px;
        overflow: hidden;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #FE2929;
        margin-left: 2px;
      margin-bottom: 20px;
    }
}
.distance1{
    margin-right: 0px;
}
.distance2{
    margin-right: 19px;
}
</style>
