<template>
  <div v-if="item != null" class="content" @click.stop="detailsMethod()">
      <div class="content-view">
          <div class="number" :class="(index+1)<=3?'active':'active1'">{{index+1}}</div>
            <div class="content-img">
                <el-image v-if="item != null" :src="item.img"></el-image>
                </div>
            <div class="content-title">
                <div class="title" v-if="item != null">{{'《'+item.name+'》'}}</div>
                <div class="price" v-if="item != null">{{'¥'+item.price}}</div>
            </div>
      </div>
      <div class="line" :class="(index+1)==leng?'active3':'active2'"></div>
  </div>
</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        index:0,
        leng:0
    },
    methods:{
        detailsMethod(){
            this.$router.push({
				path:'/bookDetails',
				query:{
				  bookID:this.item.id,
				}
			});
            this.$store.commit('changClick',1)
        }
    }
}
</script>

<style scoped lang="scss">
.content{
    width: 183px;
    height: 60px;
    padding-left: 10px;
    cursor: pointer;
    .content-view{
        display: flex;
        justify-items: center;
        align-items: center;
        .number{
            font-size: 18px;
            line-height: 25px;
        }
        .content-img{
            width: 40px;
            height: 40px;
            display: flex;
            justify-items: center;
            align-items: center;
            margin-left: 10px;
			> img{
				width: 100%;
				height: 100%;
			}
        }
        .content-title{
            width: 100px;
            overflow: hidden;
            height: 40px;
            margin-left: 10px;
            .title{
                font-size: 12px;
                line-height: 17px;
				height: 17px;
				overflow: hidden;
                color: #333333;
            }
            .price{
                font-size: 14px;
                line-height: 20px;
                color: #FE2929;
            }
        }
    }
    .line{
        width: 183px;
        height: 1px;
        background-color: #DDDDDD;
        margin-left: -10px;
        margin-top: 10px;
    }
    
}
.active{
    color: #97D9FD;
}
.active1{
    color: #999999;
}
.active2{
    background-color: #DDDDDD;
}
.active3{
    background-color: #DDDDDD 0px;
}
</style>